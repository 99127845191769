import React from "react";
import { Link } from "gatsby";
import "../css/footer.css";


const Impressum = () => {

  return (

    <div className="impressum-range">
        <div className="impressumlinks">

          <Link className="impressum" to="/impressum">
            Impressum
          </Link>

          <Link className="rechtliches" to="/rechtliches">
            Rechtliches
          </Link>
          <h7>I</h7>
          <h7>© 2021 Pneuhaus Rogenmoser GmbH</h7>
        </div>

    </div>
  );
};

export default Impressum;
