import React from "react";
import {Helmet} from "react-helmet";
import Navbar from "../components/navbar.js";
import Impressum from "../components/footer.js";
import favicon from '../assets/favicon.ico';
import "../css/jobs.css";

const JobsPage = () => {
  return (
    <div>
       <Helmet>
        <title>Pneuhaus Rogenmoser</title>
        <meta charset="utf-8" />
        <meta name="description" content="Willkommen bei Pneuhaus Rogenmoser in Auw. Ihr Reifenhändler in der Region Freiamt." />
        <meta name="keywords" content="Pneu,Pneuservice,Pneuverkauf,Pneuhandel,Reifenservice,Reifenhandel,Auw,Muri,Merenschwand,Sins,Freiamt,Aargau,Reifen," />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Melanie Rogenmoser"/>
        <meta name="copyright" content="Melanie Rogenmoser"/>
        <meta name="date" content="2022-08-09"/>
        <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
      </Helmet>
      <Navbar />
      <body>
        <section className="presentation">
          <div className="registerjobs">
              <div className="text">
                <p>Wir suchen Verstärkung!</p>
                <h1>Reifenpraktiker - Allrounder 100%</h1>
                <p>Die Pneuhaus Rogenmoser GmbH ist ein Familienunternehmen im Freiamt und wechselt Reifen, macht Reparaturen- und Unterhalsarbeiten an verschiedenen Fahrzeugtypen.</p>
                <h2>Ihre Aufgaben:</h2>
                <p>
                <p>Reifenservice und Wartungsarbeiten an Personenwagen, LKWs und landwirtschaftlichen Fahrzeugen</p>
                <p>Aktive Mitarbeit im Werkstatt-Team</p>
                </p>
                <h2>Ihr Profil:</h2>
                <p>
                <p>Abgeschlossene Ausbildung als Automobil-Assistent EBA oder ähnliches</p>
                <p>Selbständiges Arbeiten gewohnt</p>
                <p>Zuverlässige, exakte Arbeitsweise</p>
                <p>Freude am Kundenkontakt</p>
                <p>Deutsch in Wort und Schrift</p>
                <p>Führerausweis Kat. B, falls möglich CE</p>
                </p>
                <h2>Ihre Vorteile:</h2>
                <p>
                <p>Zeitgemässe Anstellungsbedingungen</p>
                <p>Kleines Team</p>
                <p>Eine äusserst selbständige und interessante Aufgabe</p>
                </p>
                <br></br>
                <p>Haben wir deine Interessen geweckt? Sende uns deine vollständigen Bewerbungsunterlagen mit Foto per E-Mail oder Post.</p>
              </div>
              <div className="clickbutton">
                <a href="/kontakt">
                  <button className="button">
                    Kontakt
                  </button>
                </a>
              </div>


              <div className="text">
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <h1>Fahrzeug-Mechaniker 100%</h1>
                <p>Die Pneuhaus Rogenmoser GmbH ist ein Familienunternehmen im Freiamt und wechselt Reifen, macht Reparaturen- und Unterhalsarbeiten an verschiedenen Fahrzeugtypen.</p>
                <h2>Ihre Aufgaben:</h2>
                <p>
                <p>Reifenservice Auto / LKW / Landmaschinen</p>
                <p>Auto- / LKW-Reparaturen</p>
                <p>Fahrzeugs-Unterhaltsarbeiten</p>
                </p>
                <h2>Ihr Profil:</h2>
                <p>
                <p>Abgeschlossene Ausbildung als Auto-, Landmaschinen- oder LKW-Mechaniker</p>
                <p>Selbständiges Arbeiten gewohnt</p>
                <p>Zuverlässige, exakte Arbeitsweise</p>
                <p>Freude am Kundenkontakt</p>
                <p>Deutsch in Wort und Schrift</p>
                <p>Führerausweis Kat. B, falls möglich CE</p>
                </p>
                <h2>Ihre Vorteile:</h2>
                <p>
                <p>Zeitgemässe Anstellungsbedingungen</p>
                <p>Kleines Team</p>
                <p>Eine äusserst selbständige und interessante Aufgabe</p>
                </p>
                <br></br>
                <p>Haben wir deine Interessen geweckt? Sende uns deine vollständigen Bewerbungsunterlagen mit Foto per E-Mail oder Post.</p>
            </div>
            <div className="clickbutton">
                <a href="/kontakt">
                  <button className="button">
                    Kontakt
                  </button>
                </a>
            </div>
          </div>
        </section>
      </body>
      <Impressum/>
    </div>

  );
};

export default JobsPage;