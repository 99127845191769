import React, { useEffect } from "react";
import { Link } from "gatsby";
import logo from "../assets/logo.png";
import "../css/navbar.css";


const Navbar = () => {

  let navLinks
  let links

  useEffect(() => {
    navLinks = document.querySelector(".nav-links");
    links = document.querySelectorAll(".nav-links link");
  }
  );

  function toggle_navbar() {
    navLinks.classList.toggle("active");
    links.forEach(link => {
      link.classList.toggle("fade");
    });
  }

  return (

    <div className="navbar-range">

      <img className="logo" src={logo} alt="Logo" />

      <div className="hamburger" onClick={toggle_navbar}>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>

      <div className="nav-links">
        <div className="links">
          <Link className="link" to="/">
            Home
          </Link>

          <Link className="link" to="/ueberuns">
            Über uns
          </Link>

          <Link className="link" to="/kontakt">
            Kontakt
          </Link>

          <div className="dropdown">
            <Link className="dropdownbutton link" to="/dienstleistungen">
              Dienstleistungen
            </Link>
            <div className="dropdown-sublink">
              <Link className="link" to="/dienstleistungen#personenwagen">
                Personenwagen
              </Link>
              <Link className="link" to="/dienstleistungen#lastwagen">
                Lastwagen
              </Link>
              <Link className="link" to="/dienstleistungen#landwirtschaft">
                Landwirtschaft
              </Link>
              <Link className="link" to="/dienstleistungen#stapler">
                Stapler
              </Link>
              <Link className="link" to="/dienstleistungen#service">
                Service
              </Link>
            </div>
          </div>
          <Link className="link" to="/jobs">
            Jobs
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
